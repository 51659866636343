import { useTranslations } from 'next-intl';

import { RcLink } from '@/components/rc-link';
import ImageWithFallback from '@/design-system-components/default-image/image-with-fallback';
import { PRODUCT_LOGOS_OPTIONS, generateImageUrl } from '@/utils/images-urls';
import { cn } from '@/utils/tailwind';
import {
  ProductTypeToRecommendationItemTitle,
  useProductTypeToRecommendationItemTitle,
} from '../hooks/use-product-type-to-recommendation-item-title';
import {
  RecommendedBannerItemGtmProps,
  RecommendedProduct,
  RecommendedProductsDataItemTypes,
} from '../types';

interface RecommendedProductV2Props {
  onClick: () => void;
  className: string;
  productImageUrl: string;
  recommendedProduct: RecommendedProduct;
  productType: ProductTypeToRecommendationItemTitle[RecommendedProductsDataItemTypes];
  productName: string;
  productLink: string;
}

export function RecommendedProductV2({
  onClick,
  className,
  productImageUrl,
  recommendedProduct,
  productType,
  productName,
  productLink,
}: RecommendedProductV2Props) {
  const t = useTranslations('recommendedProductV2');
  const productTypeToRecommendationItemTitle =
    useProductTypeToRecommendationItemTitle();

  const dataGtm = {
    name_0: productName,
    id_0: recommendedProduct.id,
    ranking: recommendedProduct.ranking,
    score_0: recommendedProduct.score,
    type_0: recommendedProduct.type,
  } as const satisfies RecommendedBannerItemGtmProps;

  const recommendedProductType = t(
    'recommendedProduct_productName_productType',
    {
      /**
       * NOTE(sontruong):
       * productName: string
       * productType: ProductTypeToRecommendationItemTitle[RecommendedProductsDataItemTypes]
       * no need to check nullish value for productName and productType
       * as they can't be null/undefined
       */
      productName,
      productType,
    },
  );

  return (
    <RcLink
      aria-label={recommendedProductType}
      data-gtm={JSON.stringify(dataGtm)}
      href={productLink}
      className={cn(
        'group flex w-[200px] flex-col items-start lg:w-[286px]',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'relative flex shrink-0 flex-col items-center justify-center overflow-hidden',
          'lg:w-[286px]',
          'lg:h-[168px]',
          'h-[120px]',
          'w-[200px]',
          'rounded-custom bg-neutral-200',
        )}
      >
        <ImageWithFallback
          src={generateImageUrl({
            url: productImageUrl,
            ...PRODUCT_LOGOS_OPTIONS,
          })}
          alt=""
          className={cn('h-full w-full object-contain', {
            'object-cover':
              productType ===
              productTypeToRecommendationItemTitle[
                RecommendedProductsDataItemTypes.GiftCard
              ],
          })}
        />
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-200 group-hover:opacity-30 group-active:opacity-50" />
      </div>
      <div className="flex h-full flex-col items-start text-left">
        <p className="line-clamp-1 text-base font-bold text-neutral-900">
          {productName}
        </p>
        <p className="mt-auto line-clamp-2 text-base font-normal text-neutral-600">
          {productType}
        </p>
      </div>
    </RcLink>
  );
}
